import React from "react"

export default function Page(props) {
  return (
    <div className="container mx-auto">
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="block text-base text-center text-brand font-semibold tracking-wide uppercase">
                Become a partner
              </span>
            </h1>

            <p className="mt-8 text-xl text-gray-500 leading-8">
              Due to the rapid rate of change in technology and Cloud. Mature
              and Startup Businesses in service industries such as Finance,
              Healthcare, Marketing, Logistics, Energy and IoT, run the risk of;
              falling behind their competitors, non-compliance, spiralling Cloud
              costs, unable to hire and retain Cloud skills, loss of relevance.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              We want to help businesses overcome these challenges and grow, in
              partnership with experts in their field.
            </p>
          </div>
          <div className="mt-6 prose prose-indigo prose-lg max-w-prose text-gray-500 mx-auto leading-8">
            <h2>Furnace Consulting Partners</h2>
            <p>
              Helping customers design, build, launch and manage applications on
              Furnace Furnace Consulting Partners are professional, consulting,
              or managed services firms that help customers of all types and
              sizes achieve their ‘data driven’ aims. These professional
              services firms include system integrators, strategic
              consultancies, agencies, managed service providers (MSPs), and
              value-added resellers.
              <br />
              <br />
              {/* BUTTON - Contact Us to find out how you could become a
              Furnace Consulting Partner */}
              {/* <button></button> */}
            </p>
            <h2>Furnace Technology Partners</h2>
            <p>
              Providing applications or services on Furnace Furnace Technology
              Partners provide applications or services as integrated components
              on Furnace. Allowing customers to innovate and seamlessly design,
              model and launch their own ‘data driven’ applications. Furnace
              Technology Partners include, large ‘cloud native’ services,
              independent ‘cross-cloud’ products and industry specific
              applications.
              <br />
              <br />
              {/* BUTTON - Contact Us to find out how you could become a
              Furnace Technology Partner */}
              {/* <button>Contact us</button> */}
            </p>
            {/* <h2>Partner Success Stories</h2>
            <p>
              We don’t have any...yet!!! Our partners have helped business
              around the world achieve their ‘data driven’ ambitions...
            </p> */}
          </div>
        </div>
      </div>
    </div>
  )
}
